// extracted by mini-css-extract-plugin
export var footer = "Zc";
export var footerBottom = "fd";
export var footerBottom__copyWrapper = "jd";
export var footerBottom__footerLink = "id";
export var footerBottom__footerSocial = "ld";
export var footerBottom__left = "gd";
export var footerBottom__links = "hd";
export var footerBottom__textDeveloper = "kd";
export var footerTop = "ad";
export var footerTop__appButton = "dd";
export var footerTop__logo = "ed";
export var footerTop__menu = "cd";
export var footerWrapper = "_c";
export var withButton = "bd";